/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SvgLoader from "../../common/SvgLoader";
import { getCookie } from "../../common/utils";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  return (
    <>
      {/* {pathname === "/partex-dashboard" ? ( */}
      {pathname !== "/" ? <div className="custom-sidebar">
        <div className="mediledger-logo" onClick={()=> navigate(`${getCookie("organization")}-dashboard`)}>
          <SvgLoader
            height={34}
            width={182}
            svgName="mediledger-logo"
            className="logo"
          />
        </div>
        <div className="custom-sidebar-pages">
          <div className="custom-sidebar-pages-container">
            <div className="home-logo">
              <SvgLoader
                height={20}
                width={20}
                svgName="Home"
                className="logo"
              />
            </div>
            <h2>Home</h2>
          </div>
          {getCookie("organization") !== "clinic" &&  <div className="custom-sidebar-pages-container">
            <div className="profile-logo">
              <SvgLoader
                height={24}
                width={24}
                svgName="download-history-icon"
                className="logo"
              />
            </div>
            <h2>Download History</h2>
          </div>}
          <div className="custom-sidebar-pages-container">
            <div className="profile-logo">
              <SvgLoader
                height={20}
                width={20}
                svgName="Profile"
                className="logo"
              />
            </div>
            <h2>Profile</h2>
          </div>
        </div>
        <div className="partex-cpy-right">
          <div className="partex-logo">
            <SvgLoader
              height={40}
              width={143}
              svgName="partex-logo"
              className="logo"
            />
          </div>
          <div className="copyright-text-conatiner">
            <p>
                Copyright © 2024 MediLedger. All Rights Reserved.{" "}
              <strong> Privacy Policy</strong>
            </p>
          </div>
        </div>
      </div> : null}
     
    </>
  );
};

export default Sidebar;
