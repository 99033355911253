/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postLoginAction } from "../../pages/LoginPage/logic";
import {  getCookie, setCookie } from "../../common/utils";
const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    if(getCookie("organization"))
    {
      navigate(`/${getCookie("organization")}-dashboard`)

    }

  }, []);
  
 
  const {data:loginData}=useSelector((state)=>state?.loginData)
  useEffect(() => {



    if(loginData?.status === 200){

      setCookie(loginData?.response?.data?.token,"accessToken")
      setCookie(loginData?.response?.data?.user?.organization,"organization")

      if(getCookie("accessToken") && getCookie("organization")){
        navigate(`/${getCookie("organization")}-dashboard`)
      }
    }
  


   
  }, [loginData?.status === 200])
  const onFinish = (values) => {
    dispatch(postLoginAction({username:values.username,password:values.password}))

    // if (values.username === "clinicadmin@gmail.com" && values.password === "Clinic@123") {
    //   // navigate("/clinic-dashboard");
    //   dispatch(postLoginAction({username:values.username,password:values.password}))


    // }
    // else if (values.username === "partexadmin@gmail.com" && values.password === "Partex@123") {
    //   navigate("/partex-dashboard");

    // }

  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 300,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="login-section2-form-content"
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input
          placeholder="Email"
          prefix={<MailOutlined />}
          type="email"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Password"
          prefix={<LockOutlined />}
        />
      </Form.Item>

      {/* <Form.Item className="login-section2-form-content-forgot-password">
        <a href="/forgot-password">Forgot password?</a>
      </Form.Item> */}

      <Form.Item>
        <Button type="primary" htmlType="submit" /* onClick={() => {navigate("/dashboard")}} */>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
