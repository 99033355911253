// import { toast } from "react-toastify";
import { genericActionCreator } from "../../app/epics";
// import {  handleLogout } from "../../common/utils";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const GET_UPLOAD_DATA = "GET_UPLOAD_DATA";
const GET_UPLOAD_DATA_SUCCESS = "GET_UPLOAD_DATA_SUCCESS";
const GET_UPLOAD_DATA_FAILURE = "GET_UPLOAD_DATA_FAILURE";
const GET_UPLOAD_DATA_RESET = "GET_UPLOAD_DATA_RESET";


const GET_SUMMARY_DATA = "GET_SUMMARY_DATA";
const GET_SUMMARY_DATA_SUCCESS = "GET_SUMMARY_DATA_SUCCESS";
const GET_SUMMARY_DATA_FAILURE = "GET_SUMMARY_DATA_FAILURE";
const GET_SUMMARY_DATA_RESET = "GET_SUMMARY_DATA_RESET";

const DELETE_DOCUMENT_DATA = "DELETE_DOCUMENT_DATA";
const DELETE_DOCUMENT_DATA_SUCCESS = "DELETE_DOCUMENT_DATA_SUCCESS";
const DELETE_DOCUMENT_DATA_FAILURE = "DELETE_DOCUMENT_DATA_FAILURE";
const DELETE_DOCUMENT_DATA_RESET = "DELETE_DOCUMENT_DATA_RESET";


const UPDATE_CONSENT_DATA = "UPDATE_CONSENT_DATA";
const UPDATE_CONSENT_DATA_SUCCESS = "UPDATE_CONSENT_DATA_SUCCESS";
const UPDATE_CONSENT_DATA_FAILURE = "UPDATE_CONSENT_DATA_FAILURE";
const UPDATE_CONSENT_DATA_RESET = "UPDATE_CONSENT_DATA_RESET";

const GET_VIEW_DETAILS= "GET_VIEW_DETAILS";
const GET_VIEW_DETAILS_SUCCESS = "GET_VIEW_DETAILS_SUCCESS";
const GET_VIEW_DETAILS_FAILURE = "GET_VIEW_DETAILS_FAILURE";
const GET_VIEW_DETAILS_RESET = "GET_VIEW_DETAILS_RESET";

export const getUploadDataAction = (payload) =>
  genericActionCreator(
    GET_UPLOAD_DATA,
    payload,
    {
      url: `${process.env.apiUrl}healthdata/uploadData`,
      method: "GET",
      auth: true,
      //   headers: {
      //     Authorization: getCookie("accessToken"),
      //     // "Content-Type": "ArrayBuffer",
      //     // ContentType: "ArrayBuffer",
      //   }
      
            
      params: {
        data_type: payload?.data_type,
        search: payload?.search,
      },
    }
  );
export const getUploadDataResetAction = () => ({
  type: GET_UPLOAD_DATA_RESET,
});

export function getUploadDataReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case GET_UPLOAD_DATA_RESET: {
    return INITIAL_STATE;
  }
  case GET_UPLOAD_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case GET_UPLOAD_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case GET_UPLOAD_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}


export const updateConsentDataAction = (payload) =>
  genericActionCreator(
    UPDATE_CONSENT_DATA,
    payload,
    {
      url: `${process.env.apiUrl}healthdata/updateConsent`,
      method: "PUT",
      auth: true,
      //   headers: {
      //     Authorization: getCookie("accessToken"),
      //     // "Content-Type": "ArrayBuffer",
      //     // ContentType: "ArrayBuffer",
      //   }
      
            
      params: {
        document_id: payload?.document_id,
        consents:payload?.consents,
      },
    }
  );
export const updateConsentDataResetAction = () => ({
  type: UPDATE_CONSENT_DATA_RESET,
});

export function updateConsentDataReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case UPDATE_CONSENT_DATA_RESET: {
    return INITIAL_STATE;
  }
  case UPDATE_CONSENT_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case UPDATE_CONSENT_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case UPDATE_CONSENT_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}


export const getDocumentViewDetailsAction = (payload) =>
  genericActionCreator(
    GET_VIEW_DETAILS,
    payload,
    {
      url: `${process.env.apiUrl}healthdata/documentDetails`,
      method: "GET",
      auth: true,
      //   headers: {
      //     Authorization: getCookie("accessToken"),
      //     // "Content-Type": "ArrayBuffer",
      //     // ContentType: "ArrayBuffer",
      //   }
    
            
      params: {
        documentId: payload?.documentId,
      },
    }
  );
export const getDocumentViewDetailsResetAction = () => ({
  type: GET_VIEW_DETAILS_RESET,
});

export function getDocumentViewDetailsReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case GET_VIEW_DETAILS_RESET: {
    return INITIAL_STATE;
  }
  case GET_VIEW_DETAILS: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case GET_VIEW_DETAILS_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case GET_VIEW_DETAILS_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}
















export const getSummaryDataAction = (payload) =>
  genericActionCreator(
    GET_SUMMARY_DATA,
    payload,
    {
      url: `${process.env.apiUrl}healthdata/summaryData`,
      method: "GET",
      auth: true,
      //   headers: {
      //     Authorization: getCookie("accessToken"),
      //     // "Content-Type": "ArrayBuffer",
      //     // ContentType: "ArrayBuffer",
      //   }
            
      params: {
        data_type: payload?.data_type,
        search: payload?.search,
      },
    }
  );
export const getSummaryDataResetAction = () => ({
  type: GET_SUMMARY_DATA_RESET,
});

export function getSummaryDataReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case GET_SUMMARY_DATA_RESET: {
    return INITIAL_STATE;
  }
  case GET_SUMMARY_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case GET_SUMMARY_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case GET_SUMMARY_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}





export const deleteDocumentAction = (payload) => {
  // Log the payload without sending it in the API call
  
  // Only send the necessary parameters to the API call
  const { patient_id, document_id } = payload;
  
  return genericActionCreator(
    DELETE_DOCUMENT_DATA,
    payload, // Send null as payload if you don't want to send the original payload
    {
      url: `${process.env.apiUrl}healthdata/deleteDocument`,
      method: "DELETE",
      auth: true,
      params: {
        patient_id: patient_id, // Send only the required params
        document_id: document_id,

      },
    }
  );
};
  
  
export const deleteDocumentResetAction = () => ({
  type: DELETE_DOCUMENT_DATA_RESET,
});

export function deleteDocumentReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case DELETE_DOCUMENT_DATA_RESET: {
    return INITIAL_STATE;
  }
  case DELETE_DOCUMENT_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case DELETE_DOCUMENT_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case DELETE_DOCUMENT_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}