/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { Button, Input, Tabs, Checkbox, Table, Switch } from "antd";
import { NodeCollapseOutlined, SearchOutlined } from "@ant-design/icons";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUploadDataAction } from "./logic";
import { getSummaryDataAction } from "../ClinicDashboard/logic";
import { getCookie } from "../../common/utils";
import axios from "axios";
import { downloadSuccessDocumentSuccessAction } from "../UploadPage/logic";
import { toast } from "react-toastify";

const PartexDashboard = () => {
  const { TabPane } = Tabs;
  const [searchText, setSearchText] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [currentTab, setCurrentTab] = useState("all");

  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();

  const { data: getUploadData, loading: getUploadDataLoading, error: getUploadDataError } = useSelector((state) => state?.getUploadDataReducer)
  const { data: summaryData, loading: summaryDataLoading, error: summaryDataError } = useSelector((state) => state?.getSummaryDataReducer)
  useEffect(() => {
    dispatch(getUploadDataAction({}))
    dispatch(getSummaryDataAction({}))


  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const daySuffix = day === 1 || day === 21 || day === 31 ? "st" : (day === 2 || day === 22) ? "nd" : (day === 3 || day === 23) ? "rd" : "th";

    const month = date.toLocaleString("en", { month: "short" });
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    return `${day}${daySuffix} ${month}, ${year}`;
  };
  useEffect(() => {
    if (getUploadData && getUploadData.data && getUploadData.data.documents) {
      const documents = getUploadData.data.documents.map(entry => entry.document);
      setFilteredData(documents);
    }
  }, [getUploadData]);



  useEffect(() => {
    if (searchText !== "") {
      const params = { search: searchText };

      if (currentTab !== "all") {
        params.data_type = currentTab;
      }

      dispatch(getUploadDataAction(params));
    }
  }, [searchText]);
  // const handleSelect = (key) => {

  //   if (selectedKeys.includes(key)) {
  //     setSelectedKeys(selectedKeys.filter((k) => k !== key));
  //   } else {
  //     setSelectedKeys([...selectedKeys, key]);
  //   }
  //   setSelectAll(false); 
  // };

  const getDocumentShareConsent1Status = (consentData) => {
    const consent = consentData?.find(item => item.consent_type === "document_share_consent1");
    return consent ? consent.status : null; 
  };

  const handleSelect = (key) => {
    if (selectedKeys.length > 0 && !selectedKeys.includes(key)) {
      setSelectedKeys([key]); 
    } else {
      if (selectedKeys.includes(key)) {
        setSelectedKeys(selectedKeys.filter((k) => k !== key));
      } else {
        setSelectedKeys([key]);
      }
    }
    setSelectAll(false); 
  };
  const documents = getUploadData?.data?.documents?.map(entry => entry.document);
  const tabData = {
    all: documents,
    clinic: documents,
    trial: documents,
    health: documents,
  };
  const columns = [
    {
      key: "select",
      render: (_, record) => (
        <div className={`partex-file-name ${record?.file_storage_status} `}>          {record?.file_storage_status === "deleted" && <div><img src="/images/delete_label.png" className="delete-label" alt="Delete Label" ></img><div className="delete-text"><div className="delete-text-content">This document has been deleted from the uploader.</div><div className="hash-section">
          <div className="hash-section-hashes">
            <div className="transactions">
              <div className="upload-hash">1. Upload hash</div>{" "}
              <div className="txn-id">
                <a href={`${process.env.explorerUrl}:/?tab=transactions&transId=${record?.upload_document_transaction_id?.blockchain_txn_id}`} className="txn-id" target="__blank"> {record?.upload_document_transaction_id?.blockchain_txn_id} </a>
              </div>
            </div>
            <div className="line"></div>
            <div className="transactions">
              <div className="delete-hash">2. Delete hash</div>{" "}
              <div className="txn-id">
                <a href={`${process.env.explorerUrl}:/?tab=transactions&transId=${record?.delete_document_transaction_id?.blockchain_txn_id}`} className="txn-id" target="__blank"> {record?.delete_document_transaction_id?.blockchain_txn_id} </a>
              </div>
            </div>
          </div></div></div></div>}

        {getDocumentShareConsent1Status(record?.consents) === 0 ?
          <div>
            <div className="delete-text revoked consent-data">
              <div className="cant-download-text-content">
  This file cannot be downloaded, because the &quot;Download access&quot; has been revoked by the uploader.                
                {/* , here is the transaction hash
  <span className="consent-hash-section">{record?.hash}</span> */}
              </div>
            </div>
          </div>
          : null}
        {record?.file_storage_status !== "deleted" && <Checkbox
          checked={selectedKeys.includes(record?.document_id)}
          onChange={() => handleSelect(record?.document_id)}
          className="checkbox-partex"
        />} </div>
      ),
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      render: (text, record) => (
        <span className={`partex-file-name ${record?.file_storage_status}`}>
          {/* {record?.file_storage_status === "deleted" && <div><img src="/images/delete_label.png" className="delete-label" alt="Delete Label" ></img><div className="delete-text">This is deleted</div></div>} */}

          <img src="/images/file-icon.png" className="file-icon" alt="File Icon" ></img>
          <div className="name">{text}</div>
        </span>
      ),
    }, 
    { title: "Patient ID", dataIndex: "patient_id", key: "patient_id",render: (text) => <div className="data-type">{text || "----"}</div> },

    { title: "Data Type", dataIndex: "data_type", key: "data_type",      render: (text) => <span className="data-type" >{text || "-"}</span>,
    },
    {
      title: "Hospital Name",
      dataIndex: "hospital_name",
      key: "hospital_name",
      render: (text) => <span >{text || "-"}</span>,
    },
    {
      title: "Data Transaction Id",
      dataIndex: "hash",
      key: "hash",
      render: (_,record) => record?.file_storage_status !== "deleted" ? <div className="txn-id"><a href={`${process.env.explorerUrl}:/?tab=transactions&transId=${record?.upload_document_transaction_id?.blockchain_txn_id}`} className="txn-id" target="__blank"> {record?.upload_document_transaction_id?.blockchain_txn_id} </a></div>: <div className="txn-id"><a href={`${process.env.explorerUrl}:/?tab=transactions&&transId=${record?.delete_document_transaction_id?.blockchain_txn_id}`} className="txn-id" target="__blank">{record?.delete_document_transaction_id?.blockchain_txn_id }</a></div>,
    },
    { title: "Uploaded On", dataIndex: "uploaded_on", key: "uploaded_on", render: (text) => formatDate(text) },
    {
      title: "Data Size",
      dataIndex: "data_size_bytes",
      key: "data_size_bytes", 
      render: (text) => formatDataSize(text),
    },    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Button className="custom-btn-hollow">View Details</Button>
    //   ),
    // },
  ];


  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = tabData[filteredData]?.filter((item) =>
      item.fileName.toLowerCase()?.includes(value)
    );
    setFilteredData(filtered);
  };

  const formatDataSize = (bytes) => {
    let sizeString;
    let unit;

    if (bytes < 1024) {
      sizeString = `${bytes}`;
      unit = "Bytes";
    } else if (bytes < 1048576) {
      sizeString = `${(bytes / 1024).toFixed(2)}`;
      unit = "KB";
    } else if (bytes < 1073741824) {
      sizeString = `${(bytes / 1048576).toFixed(2)}`;
      unit = "MB"; 
    } else {
      sizeString = `${(bytes / 1073741824).toFixed(2)}`;
      unit = "GB";
    }

    return (
      <>
        {sizeString} <span className="format">{unit}</span>
      </>
    );
  };
  const handleTabChange = (key) => {
    setCurrentTab(key);
    dispatch(getUploadDataAction(key !== "all" ? { data_type: key } : {}))

    setFilteredData([]);

    setSearchText(""); setSelectedKeys([]);
    setSelectAll(false);
  };

  // const handleSelect = (key) => {
  //   const newSelectedKeys = selectedKeys.includes(key)
  //     ? selectedKeys.filter((k) => k !== key)
  //     : [...selectedKeys, key];
  //   console.log("Handle select: ", key);
  //   setSelectedKeys(newSelectedKeys);
  //   setSelectAll(newSelectedKeys.length === filteredData.length);
  //   console.log(newSelectedKeys.length === filteredData.length);
  // };


  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   console.log("Select all: ", selectAll);
  //   if (selectAll) {
  //     // setSelectedKeys([]);
  //     console.log("Select all in if: ", selectAll);
  //     setSelectedKeys(filteredData.map((item) => item.key));
  //     console.log("Length for selected: ", selectedKeys.length);
  //     if(selectedKeys.length !== tabData["1"].length){
  //       console.log("Seconf if: ", selectedKeys);
  //     }
  //   } else {
  //     // setSelectedKeys(filteredData.map((item) => item.key));
  //     setSelectedKeys([]);
  //     console.log("Else wala part: ", selectedKeys);
  //   }
  // };

  // const handleSelectAll = (e) => {
  //   // console.log(e.target);
  //   // const checked = e.target.checked;
  //   // setSelectAll(checked);
  //   // setSelectedKeys(checked ? filteredData.map((item) => item.key) : []);
  // };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;

      if (newSelectAll) {
        setSelectedKeys(filteredData.map((item) => item.key));
      } else {
        setSelectedKeys([]);
      }

      return newSelectAll;
    });
  };

  let downloadType = 1;

  useEffect(() => {
    if (selectedKeys.length === 1) {
      downloadType = 1;
    } else {
      downloadType = 2;
    }
  }, [selectedKeys]);

  const downloadFile = async (fileId, fileName) => {
    try {
      const response = await axios.post(
        `${process.env.apiUrl}healthdata/downloadFile?documentId=${selectedKeys[0]}`,
        {}, // Empty object for the request body if no additional data is needed
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${getCookie("accessToken")}`, // Correctly place headers outside the payload
          },
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            // Optionally, update a state to show the progress to the user
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          downloadSuccessDocumentSuccessAction({
            response: response,
          })
        );



        const file_path = `${process.env.apiUrl}${response?.data?.data?.file_path}`;

        const link = document.createElement("a");
        link.href = file_path; // Use the file path from the response
        link.target = "_blank"; // Open in a new tab
        link.setAttribute("download", response?.data?.data?.file_name || "downloaded-file"); // Optional: Set the downloaded file's name
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to open the file in a new tab
        link.remove(); // Remove the link from the DOM after opening
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", fileName); // Set the downloaded file's name
        // document.body.appendChild(link);
        // link.click();
        // link.remove();


        // Optionally, navigate to a success page
        navigate("/success-page?download=true");
      }
    } catch (error) {
      toast.error(
        <span className="delete-toast-span">
          Consent to download document not available.
        </span>,
        { className: "delete-toast" }
      
      );

      console.error("Download failed:", error);
      // Optionally, handle errors
    }
  };

  const summaryIcons =["home-icon","mixturemed-icon","cardiology-icon"]

  const rowClassName = (record) => {
    return (record.file_storage_status === "deleted" || getDocumentShareConsent1Status(record?.consents) === 0) ? `${getCookie("organization")} disabled-row` : "";
  };

  return (
    <div className="home-page">
      <div className="summary-section">
        {summaryData?.data?.map((item, index) => (
          <div className="summary-card" key={index}>
            <div className="summary-card-mask">
              <div className="title">{item?.type}</div>
              <div className="value">
                {formatDataSize(item.totalSize)}
                {/* <span className="format">{item.totalSize < 1048576 ? "MB" : "GB"}</span> */}
              </div>
              <SvgLoader
                width={50}
                height={50}
                svgName={summaryIcons[index]}
                className="svg-icons"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="metadata-section">
        <div className="metadata-section-header">
          <div className="metadata-section-header-title">
            Uploaded Documents
          </div>
        </div>
        <div className="tables-container">
          <Input
            placeholder="Search by file name"
            onChange={handleSearch}
            value={searchText}
            prefix={<SearchOutlined />}
            className="search-input download-search"
          />
          <Button
            className="download-data-btn"
            disabled={selectedKeys.length === 0}
            onClick={() => { downloadFile() }}
            // onClick={() => {
            //   navigate(
            //     "/success-page?download=true&&downloadType=" + downloadType
            //   );
            // }}
            icon={
              <SvgLoader
                width={10}
                height={12}
                svgName="download-icon-modal-white"
                className="download-icon-modal"
              />
            }
          >
            Download
          </Button>
          <Tabs defaultActiveKey="all" onChange={handleTabChange}>
            <TabPane tab="All Data" key="all">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName} />
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Clinic Data" key="clinic">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName} />
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Trial Data" key="trial">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName} />
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Health Data" key="health">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName} />
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default PartexDashboard;
