/* eslint-disable no-unused-vars */
// App.js or main component file
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import ErrorPage from "./common/ErrorPage";
import "../public/styles/main.scss";
import "./app.css";
import Sidebar from "./components/Sidebar";
import CustomHeader from "./components/CustomHeader";
import LoginPage from "./pages/LoginPage";
import UploadPage from "./pages/UploadPage";
import ClinicDashboard from "./pages/ClinicDashboard";
import PartexDashboard from "./pages/PartexDashboard";
import SuccessScreen from "./components/SuccessScreen";
import { ToastContainer } from "react-toastify";
import { getCookie } from "./common/utils";

const MainLayout = () => (
  <div className="app-layout">
    <ToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
    />
    <Sidebar />
    <div className="app-header-layout">
      <CustomHeader />
      <div className="app-outlet-layout">
        <Outlet />
      </div>
    </div>
  </div>
);

// Logic to protect routes
const ProtectedRoute = ({ element, allowedRole }) => {
  const userRole = getCookie("organization"); 

  if (userRole === allowedRole) {
    return element;
  } else {
    return <Navigate to={`/${userRole}-dashboard`} />; 
  }
};

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <LoginPage /> },
      {
        path: "/clinic-dashboard",
        element:<ClinicDashboard />

        // element: (
        //   <ProtectedRoute
        //     element={<ClinicDashboard />}
        //     allowedRole="clinic"
        //   />
        // ),
      },
      {
        path: "/partex-dashboard",
        element:<PartexDashboard />

        // element: (
        //   <ProtectedRoute
        //     element={<PartexDashboard />}
        //     allowedRole="partex"
        //   />
        // ),
      },
      { path: "/success-page", element: <SuccessScreen /> },
      { path: "/upload", element: <UploadPage /> },
    ],
  },
]);

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
