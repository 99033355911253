/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Radio,
  Upload,
  Button,
  Checkbox,
  Typography,
  Progress,
  Table,
} from "antd";
import SvgLoader from "../../common/SvgLoader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import { getCookie } from "../../common/utils";
import { useDispatch } from "react-redux";
import { uploadSuccessDocumentReducer } from "./logic";
import { deleteDocumentAction } from "../ClinicDashboard/logic";
import { data } from "autoprefixer";

const { TextArea } = Input;
const { Link } = Typography;

const UploadPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const selectedValue = query.get("uploadType");
  const reupload = query.get("reupload");
  const patient_id = query.get("patientId");
  const document_id = query.get("document_id");
  const data_type = query.get("data_type");
  const [patientId,setPatientId]=useState(patient_id || "")

  const consent1 = query.get("consent1");
  const consent2 = query.get("consent2");

  const parseConsent = (value) => {
    if (value === "1") return true;
    if (value === "0") return false;
    return false; // Default to unchecked if value is not "1" or "0"
  };
  
  const [termAndPolicyConsent, setTermAndPolicyConsent] = useState(false);
  const [documentShareConsent1, setDocumentShareConsent1] = useState(parseConsent(consent1));
  const [documentShareConsent2, setDocumentShareConsent2] = useState(parseConsent(consent2));
  
  // Determine if the checkboxes should be disabled
  const isConsent1Disabled = consent1 !== null;
  const isConsent2Disabled = consent2 !== null;
  const [dataType, setDataType] = useState(1);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For tracking upload progress

  const navigate = useNavigate();


  useEffect(() => {
    if(data_type){

      if (data_type === "clinic") {
        setDataType(1);
      } else if (data_type === "trial") {
        setDataType(2);
      } else if (data_type === "health") {
        setDataType(3);
      }
    }
   
  }, [data_type]);

  const handleDocumentShareConsent1 = (e) => {
    setDocumentShareConsent1(e.target.checked);
  };

  const handleDocumentShareConsent2 = (e) => {
    setDocumentShareConsent2(e.target.checked);
  };

  const handleTermAndPolicyConsent = (e) => {
    setTermAndPolicyConsent(e.target.checked);
  };

  const handleUpload = (info) => {
    const { file } = info;
    setFile(file);
  };

  const removeFile = () => {
    setFile(null);
  };

  const uploadFile = async () => {
    if (!file) return;
    


    const consentData = [
      {consent_type:"terms_and_policy",status: termAndPolicyConsent},
      {consent_type:"document_share_consent1",status: documentShareConsent1},
      {consent_type:"document_share_consent2",status: documentShareConsent2},
    ]

    const formData = new FormData();
    formData.append("file", file); 
    formData.append("patient_id", selectedValue === "1" ? patientId : "");
    formData.append("data_type", dataType);
    formData.append("metadata", "metadata");
    formData.append("consents", JSON.stringify(consentData));
    formData.append("description", "description");
    formData.append("action",reupload ? "reupload" : "upload");
    formData.append("previous_document_id", document_id);
    if(reupload)
    {
      dispatch(deleteDocumentAction({document_id:document_id}))

    }


    try {
      const response = await axios.post(`${process.env.apiUrl}healthdata/uploadData`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${getCookie("accessToken")}`,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadProgress(percent); 
        },
      });
      
      if (response.status === 201) {
        setUploadStatus(true); 
    
        const action = {
          type: "UPLOAD_SUCCESS_DATA_SUCCESS", 
          payload: { response: response.data },
        };
    
        dispatch(action);
    
        navigate("/success-page");
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <div className="upload-page">
      <div className="upload-page-back-btn">
        <Button onClick={() => navigate("/clinic-dashboard")}>
          <ArrowLeftOutlined />
          Back
        </Button>
      </div>
      <div className="upload-page-wrapper">
        <Form layout="vertical" className="upload-form">
          <div className="form-head">{reupload ? "Reupload Data" : "Upload Data"}</div>
          <Form.Item>
            <Radio.Group
              defaultValue={dataType}
              value={dataType}
              onChange={(e) => setDataType(e.target.value)}
              className="radio-group"
              disabled={reupload === "true" ? true : false}
            >
              <Radio value={1}>Clinic Data</Radio>
              <Radio value={2}>Trial Data</Radio>
              <Radio value={3}>Health Data</Radio>
            </Radio.Group>
          </Form.Item>
          {selectedValue === "1" ? (
            <Form.Item
              name="patientId"
              rules={[{ required: true, message: "Please enter Patient ID" }]}
            >
              <Input id="patientId" placeholder="Patient ID" className="patient-id-input" defaultValue={patient_id && patient_id} disabled={patient_id} onChange={(e) => setPatientId(e.target.value)}/>
            </Form.Item>
          ) : (
            <p></p>
          )}
          <Form.Item className="upload-dragger">
            {!file ? (
              <Upload.Dragger
                name="files"
                accept=".xlsx,.pdf,.txt,.csv"
                beforeUpload={() => false}
                onChange={handleUpload}
              >
                <p className="ant-upload-drag-icon">
                  <SvgLoader
                    width={50}
                    height={50}
                    svgName={"upload-icon"}
                    className="upload-icon"
                  />
                </p>
                <p className="ant-upload-text">
                  Drag & drop data file or <Link>Browse</Link>
                </p>
                <p className="ant-upload-hint">
                  (Acceptable file formats are .xlsx, .pdf, or .txt)
                </p>
                <p style={{ marginTop: "8px" }}>
                  For reference <Link>Download a template</Link>
                </p>
              </Upload.Dragger>
            ) : (
              <div>
                <div className="file-name-container">
                  <span>
                    <SvgLoader
                      width={20}
                      height={20}
                      svgName={"success-icon"}
                      className="success-icon"
                    />
                    {file.name}
                  </span>
                  <span>
                    100%{" "}
                    <Button type="link" onClick={removeFile}>
                      {" "}
                      <SvgLoader
                        width={20}
                        height={20}
                        svgName={"delete-icon"}
                        className="delete-icon"
                      />
                    </Button>
                  </span>
                </div>
                <div className="progress-container">
                  <Progress percent={100} className="progress-bar" />
                </div>
              </div>
            )}
          </Form.Item>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Any additional Comments (Optional)"
              className="textarea-input"
            />
          </Form.Item>
          <div className="consent-container">
            <Form.Item>
              <Checkbox
                checked={reupload === "true" ? true : termAndPolicyConsent}
                onChange={handleTermAndPolicyConsent}
                className="consent-checkbox"
                disabled={reupload === "true" ? true : false} 
              >
                I agree to all the <Link>Terms of Services</Link> and{" "}
                <Link>Privacy Policy</Link>.
              </Checkbox>
              <Checkbox
                checked={documentShareConsent1}
                onChange={(e) => setDocumentShareConsent1(e.target.checked)}
                className="consent-checkbox"
                disabled={isConsent1Disabled} 
              >
      Consent 1
              </Checkbox>
              <Checkbox
                checked={documentShareConsent2}
                onChange={(e) => setDocumentShareConsent2(e.target.checked)}
                className="consent-checkbox"
                disabled={isConsent2Disabled}
              >
      Consent 2
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item className="upload-btn">
            <Button
              className="custom-btn-solid"
              block
              disabled={reupload ? !file: (selectedValue === "1"  ?  !termAndPolicyConsent || !file || !patientId : !termAndPolicyConsent || !file )}
              onClick={uploadFile} 
            >
              {reupload ? "Reupload" : "Upload"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UploadPage;
