/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Column from "antd/es/table/Column";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../common/utils";
import { deleteDocumentResetAction } from "../../pages/ClinicDashboard/logic";
// import SvgLoader from "../../common/SvgLoader";

const SuccessScreen = () => {
  const navigate = useNavigate();
  const {data:uploadSuccessData}=useSelector((state) => state.uploadSuccessDocumentReducer);
  const {data:downloadSuccessDocument, loading:downloadSuccessDocumentLoading, error:downloadSuccessDocumentError} = useSelector((state) => state?.downloadSuccessDocumentReducer)
  const {data:deleteData, loading:deleteDataLoading, error:deleteDataError} = useSelector((state) => state?.deleteDocumentReducer)
  const dispatch = useDispatch();
  const[uploadSuccessTableData,setUploadSuccessTableData] = useState([])  

  const formatCustomDate = (dateString) => {
    const date = new Date(dateString);
  
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
  
    return `${month} ${day}th, ${year} ${hours}:${minutes}${ampm}`;
  };

  const successUploadData = [
    {
      key: "1",
      feildTitle: "File Name",
      value: uploadSuccessData?.data?.file_name,
    },
    {
      key: "2",
      feildTitle: "Patient Id",
      value: ( <div className="txn-id">{uploadSuccessData?.data?.patient_id}</div>), 
    },
    {
      key: "3",
      feildTitle: "Data Type",
      value: uploadSuccessData?.data?.data_type === "1" 
        ? "Clinic Data" 
        : uploadSuccessData?.data?.data_type === "2" 
          ? "Trial Data" 
          : uploadSuccessData?.data?.data_type === "3" 
            ? "Health Data" 
            : "Unknown",  

      render: (text) => <div className="data-type">{text}</div>
    },
    {
      key: "4",
      feildTitle: "Document Id",
      value: ( <div className="txn-id">{uploadSuccessData?.data?.document_id}</div>),
    },
    {
      key: "5",
      feildTitle: "Data Transaction Id",
      value: (
        getCookie("organization") === "clinic" ? 
          <div className="txn-id">  {uploadSuccessData?.data?.upload_document_transaction_id?.blockchain_txn_id} </div>: 
          <div className="txn-id">
            <a 
              href={`${process.env.explorerUrl}/?tab=transactions&transId=${uploadSuccessData?.data?.upload_document_transaction_id?.blockchain_txn_id}`} 
              className="txn-id" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {uploadSuccessData?.data?.upload_document_transaction_id?.blockchain_txn_id}
            </a>
          </div>
      ),
    }
    ,
    {
      key: "6",
      feildTitle: "Upload Date",
      value: formatCustomDate(uploadSuccessData?.data?.uploaded_on),
    },
  ];


  const successDownloadData = [
    {
      key: "1",
      feildTitle: "File Name",
      value: downloadSuccessDocument?.response?.data?.data?.file_name,
    },
    // {
    //   key: "2",
    //   feildTitle: "Patient Id",
    //   value: ( <div className="txn-id">{uploadSuccessData?.data?.data?.upload_document_transaction_id?.patient_id}</div>),
    // },
    {
      key: "2",
      feildTitle: "Data Type",
      value: downloadSuccessDocument?.response?.data?.data?.data_type  ,      render: (text) => <div className="data-type">{text}</div>
    },
    {
      key: "3",
      feildTitle: "Document Id",
      value:( <div className="txn-id">{ downloadSuccessDocument?.response?.data?.data?.upload_document_transaction_id?.document_id}</div>),
    },
    {
      key: "4",
      feildTitle: "Data Transaction Id",
      value: (
        getCookie("organization") === "clinic" ? 
          <div className="txn-id">
            {downloadSuccessDocument?.response?.data?.data?.upload_document_transaction_id?.blockchain_txn_id}
          </div> : 
          <div className="txn-id">
            <a 
              href={`${process.env.explorerUrl}/?tab=transactions&transId=${downloadSuccessDocument?.response?.data?.data?.upload_document_transaction_id?.blockchain_txn_id}`} 
              className="txn-id" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {downloadSuccessDocument?.response?.data?.data?.upload_document_transaction_id?.blockchain_txn_id}
            </a>
          </div>
      ),
    },
     
    {
      key: "5",
      feildTitle: "Upload Date",
      value: formatCustomDate(downloadSuccessDocument?.response?.data?.data?.uploaded_on),
    },
  ];
  

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const download = query.get("download");
  const downloadType = query.get("downloadType");

  /* Loader try 1*/
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    // Simulating data fetching with a timeout
    const fetchData = async () => {
      // Simulate an API call
      await new Promise((resolve) => setTimeout(resolve, 17000)); // Simulate a 3-second loading time
      setData({ message: "Data Loaded!" }); // Set your data here
      setLoading(false); // Data is loaded; set loading to false
    };
    fetchData();
  }, []);

  useEffect(()=> {
    if(!uploadSuccessData?.data?.patient_id?.length){
      const filteredTableData = successUploadData.filter(item => item.feildTitle !== "Patient Id");
      setUploadSuccessTableData(filteredTableData)
    }
  },[JSON.stringify(uploadSuccessData)])

  const columns = [
    { title: "File Name", dataIndex: "fileName", key: "fileName" },
    { title: "Data Type", dataIndex: "dataType", key: "dataType",      render: (text) => <div className="data-type">{text}</div>
    },
    {
      title: "Data Hash",
      
      dataIndex: "dataHash",
      key: "dataHash",
      render: (text) => <a href="/">{text}</a>,
    },
    { title: "Uploaded On", dataIndex: "uploadedOn", key: "uploadedOn" },
    { title: "Data Size", dataIndex: "dataSize", key: "dataSize" },
  ];
  const [isLoaderComplete, setIsLoaderComplete] = useState(false);

  const handleLoaderComplete = () => {
    // This function will be called once the loader GIF finishes loading
    setIsLoaderComplete(true);
  };
  useEffect(() => {
    if (deleteData?.status_code === 200) {
      dispatch(deleteDocumentResetAction({}))



 
  

    }


  }, [deleteData?.status_code === 200])
  const dataTable = [
    {
      key: "1",
      fileName: "John Brown",
      dataType: 32,
      dataHash: "New York No. 1 Lake Park",
      uploadedOn: ["nice", "developer"],
      dataSize: "32GB",
    },
    {
      key: "2",
      fileName: "John Brown",
      dataType: 32,
      dataHash: "New York No. 1 Lake Park",
      uploadedOn: ["nice", "developer"],
      dataSize: "32GB",
    },
    {
      key: "3",
      fileName: "John Brown",
      dataType: 32,
      dataHash: "New York No. 1 Lake Park",
      uploadedOn: ["nice", "developer"],
      dataSize: "32GB",
    },
  ];

  return (
    <div className="success-page">
      <div className="upload-page-back-btn">
        <Button onClick={() => getCookie("organization") === "clinic" ? navigate("/clinic-dashboard") : navigate("/partex-dashboard")}>
          <ArrowLeftOutlined />
          Back
        </Button>
      </div>
      <div className="upload-success">
        {" "}
        {/* <SvgLoader
          width={180}
          height={180}
          svgName={"upload-success"}
          className="upload-success"
        /> */}
        {loading && !download ? (
          <div className="custom-loader">
            <img src="/images/loader.gif" alt="Loading"           onLoad={handleLoaderComplete} // Trigger when the loader is finished
            />
          </div>
        ) : (
          <div className="upload-success-container">
            {/* <SvgLoader
            width={300}
            height={300}
            svgName="secure-upload"
            // className="upload-success"
          /> */}
            <img
              src="/images/secure-upload.png"
              alt="Secure upload"
              className="secure-upload"
            />
            <div className="upload-success-details-content">
              <h2>
                {download ? "Download Successful!" : "Data Uploaded Securely!"}
              </h2>
              <p>
                {download
                  ? "Your data is successfully downloaded!"
                  : "Your data is successfully uploaded!"}{" "}
                <br />
                These details are saved on the dashboard for future reference.
              </p>
              {downloadType !== 1 ? (
                <div className="upload-success-table">
                  <div className="upload-success-table-contents">
                    {download ?  <Table dataSource={successDownloadData}>
                      <Column dataIndex={"feildTitle"} key="key" />
                      <Column dataIndex={"value"} key="tags" />
                    </Table> : <Table dataSource={uploadSuccessTableData.length ? uploadSuccessTableData : successUploadData}>
                      <Column dataIndex={"feildTitle"} key="key" />
                      <Column dataIndex={"value"} key="tags" />
                    </Table>}
                  </div>
                </div>
              ) : (
                <div className="upload-success-table-multiple">
                  <Table columns={columns} dataSource={dataTable} />
                </div>
              )}
              <div className="dashboard-btn">
                <Button onClick={() => navigate(`/${getCookie("organization")}-dashboard`)}>
                  Go to Dashboard
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessScreen;
