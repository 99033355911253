import React from "react";
import { Table } from "antd";

const CustomTable = ({ tableData, columns ,rowClassName}) => {
  return (
    <div>
      <Table columns={columns} dataSource={tableData} pagination={{ pageSize: 10 }} rowClassName={rowClassName} />
    </div>
  );
};

export default CustomTable;
