import { combineReducers } from "@reduxjs/toolkit";
import { modalReducer } from "./Modal/logic";
import { sessionReducer } from "./Status/logic";
import {
  customNotificationReducer,
  notificationReducer,
} from "../common/Notification/logic";
import { getImagesReducer } from "./pics/Pics.logic";
import { postLoginReducer } from "../pages/LoginPage/logic";
import { deleteDocumentReducer, getDocumentViewDetailsReducer, getSummaryDataReducer, getUploadDataReducer, updateConsentDataReducer } from "../pages/ClinicDashboard/logic";
import { downloadSuccessDocumentReducer, uploadSuccessDocumentReducer } from "../pages/UploadPage/logic";

const rootReducer = combineReducers({
  modal: modalReducer,
  session: sessionReducer,
  notification: notificationReducer,
  customNotification: customNotificationReducer,
  images: getImagesReducer,
  loginData:postLoginReducer,
  getUploadDataReducer,
  getSummaryDataReducer,
  uploadSuccessDocumentReducer,
  deleteDocumentReducer,
  getDocumentViewDetailsReducer,
  updateConsentDataReducer,
  downloadSuccessDocumentReducer
});

export default rootReducer;
