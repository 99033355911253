/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SvgLoader from "../../common/SvgLoader";
import { Select } from "antd";
import { handleLogout, removeCookies } from "../../common/utils";

const CustomHeader = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  // const handleLogout = () => {
    
  //   // Remove cookies and local storage data
  //   removeCookies("token");
  //   removeCookies("organization");
  //   // ["token", "organization"].forEach((key) => {
  //   //   removeCookies(key);
  //   //   localStorage.removeItem(key);
  //   // });

  //   // Navigate to the home page after a delay
  //   setTimeout(() => navigate("/"), 1500);
  // };

  if (location.pathname === "/") {
    return null;
  }

  return (
    <>
      {pathname !== "/" ? (
        <div className="custom-header">
          <div className="hospital-icon">
            {/* <SvgLoader
              height={40}
              width={136}
              svgName="hospital"
              className="logo"
            /> */}
          </div>
          <div className="custom-actions">
            {/* <div className="custom-actions-dropdown">
              <Select
                options={[
                  {
                    value: "1",
                    label: "User",
                  }
                ]}
                className="custom-actions-dropdown-select"
              />
              <div className="user-greeting-home">
                <p>User</p>
              </div>
              <div className="user-photo-icon">
                <img src="/images/User.png" alt="user" />
              </div>
            </div> */}
          
            
            <div className="export-icon">
              <SvgLoader
                height={24}
                width={24}
                svgName="export-icon"
                className="logo"
                // onClick={() => navigate("/")}
                onClick={()=>handleLogout()}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CustomHeader;
