/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SvgLoader from "../../common/SvgLoader";
import { Button, Input, Tabs, Modal, Checkbox, Select, Tag } from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getCookie } from "../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocumentAction, deleteDocumentResetAction, getDocumentViewDetailsAction, getSummaryDataAction, getUploadDataAction, updateConsentDataAction, updateConsentDataResetAction } from "./logic";
import axios from "axios";
import { downloadSuccessDocumentSuccessAction } from "../UploadPage/logic";

const ClinicDashboard = () => {
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isConsentDisabled, setIsConsentDisabled] = useState(true);
  const [consentChecked1, setConsentChecked1] = useState(true);
  const [consentChecked2, setConsentChecked2] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const {data:deleteData, loading:deleteDataLoading, error:deleteDataError} = useSelector((state) => state?.deleteDocumentReducer)
  const { data: getUploadData, loading: getUploadDataLoading, error: getUploadDataError } = useSelector((state) => state?.getUploadDataReducer)
  const { data: summaryData, loading: summaryDataLoading, error: summaryDataError } = useSelector((state) => state?.getSummaryDataReducer)
  const {data:getDocumentViewDetails, loading:getDocumentViewDetailsLoading, error:getDocumentViewDetailsError} = useSelector((state) => state?.getDocumentViewDetailsReducer)
  const {data:updateConsentResponse, loading:updateConsentResponseLoading, error:updateConsentResponseError} = useSelector((state) => state?.updateConsentDataReducer)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUploadDataAction({}))
    dispatch(getSummaryDataAction({}))


  }, [])
  useEffect(() => {
    setModalData(getDocumentViewDetails?.data)
    setConsentChecked1(getDocumentViewDetails?.data?.consents[1]?.status)
    setConsentChecked2(getDocumentViewDetails?.data?.consents[2]?.status)


    
  },[
    getDocumentViewDetails?.status_code === 200,
  ])


  useEffect(() => {
    if (deleteData?.status_code === 200) {



      toast.success(
        <>
          <span className="delete-toast-span">
          Patient data file deleted successfully.{" "}
            <Button
              className="delete-toast-span-details"
              onClick={handleDetailsModalClick}
              classNames="view-details-modal-btn"
            >
            View Details
            </Button>
          </span>
        </>,
        { className: "delete-toast" }
      );

      setTimeout(()=>{

        dispatch(getUploadDataAction({search:searchText,data_type:currentTab}))
      },3000)
      dispatch(deleteDocumentResetAction({}))

    }


  }, [deleteData?.status_code === 200])


  useEffect(()=> {
    if(updateConsentResponse?.status_code === 200)
    {
      setIsModalVisible(false)
      toast.success(
        <>
          <span className="delete-toast-span">
        Consents Updated Successfully.          </span>
        </>,
        { className: "delete-toast" }
      );

    }
    dispatch(updateConsentDataResetAction({}))
    
  },[updateConsentResponse?.status_code === 200])

  const formatCustomDate = (dateString) => {
    const date = new Date(dateString);
  
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
  
    return `${month} ${day}th, ${year} ${hours}:${minutes}${ampm}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = date.getDate();
    const daySuffix = day === 1 || day === 21 || day === 31 ? "st" : (day === 2 || day === 22) ? "nd" : (day === 3 || day === 23) ? "rd" : "th";
    
    const month = date.toLocaleString("en", { month: "short" });
    const year = date.getFullYear().toString().slice(-2); 
  
    return `${day}${daySuffix} ${month}, ${year}`;
  };
  

  const formatDataSize = (bytes) => {
    let sizeString;
    let unit;
  
    if (bytes < 1024) {
      sizeString = `${bytes}`;
      unit = "Bytes";
    } else if (bytes < 1048576) {
      sizeString = `${(bytes / 1024).toFixed(2)}`;
      unit = "KB"; 
    } else if (bytes < 1073741824) {
      sizeString = `${(bytes / 1048576).toFixed(2)}`;
      unit = "MB";
    } else {
      sizeString = `${(bytes / 1073741824).toFixed(2)}`;
      unit = "GB"; 
    }
  
    return (
      <>
        {sizeString} <span className="format">{unit}</span>
      </>
    );
  };
  
 
  const documents = getUploadData?.data?.documents?.map(entry => entry.document);
  const tabData = {
    all: documents,
    clinic: documents,
    trial: documents,
    health: documents,
  };
  const [filteredData, setFilteredData] = useState();

  const columns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      render: (text, record) => (
        <span className={`clinic-file-name ${record?.file_storage_status}`}>
          {record?.file_storage_status === "deleted" && <img src="/images/delete_label.png" className="delete-label" alt="Delete Label" ></img>}

          <img src="/images/file-icon.png" className="file-icon" alt="File Icon" ></img>
          <div className="name">{text}</div>
        </span>
      ),
    },
    { title: "Patient ID", dataIndex: "patient_id", key: "patient_id",render: (text) => <div className="data-type">{text || "----"}</div> },
    // { title: "Deleted Status ", dataIndex: "file_storage_status", key: "file_storage_status" },

    { title: "Data Type", dataIndex: "data_type", key: "data_type",render: (text) => <div className="data-type">{text}</div> },
    {
      title: "Data Transaction Id",
      dataIndex: "hash",
      key: "hash",
      render: (_,record) => record?.file_storage_status !== "deleted" ?  <div className="txn-id">{record?.upload_document_transaction_id?.blockchain_txn_id} </div>: <div className="txn-id"> {record?.delete_document_transaction_id?.blockchain_txn_id }</div>,
    },
   
    { title: "Uploaded On", dataIndex: "uploaded_on", key: "uploaded_on", render: (text) => formatDate(text) },
    {
      title: "Data Size",
      dataIndex: "data_size_bytes",
      key: "data_size_bytes",
      render: (text) => formatDataSize(text),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record,index) => (
        <Button
          className="custom-btn-hollow"
          onClick={() => {
            if (record?.file_storage_status === "deleted") {
              setModalData(record);
              setIsDetailsModalOpen(true);
            } else {
              showModal(record);
            }
          }}
        >          View Details
        </Button>
      ),
    },
  ];
  

  useEffect(() => {
    if (getUploadData && getUploadData.data && getUploadData.data.documents) {
      const documents = getUploadData.data.documents.map(entry => entry.document);
      setFilteredData(documents);
    }
  }, [getUploadData]);
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = tabData["all"]?.filter((item) =>
      item.file_name.toLowerCase()?.includes(value)
    );
    setFilteredData(filtered);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
    dispatch(getUploadDataAction(key !== "all" ? { data_type: key } : {}))

    setFilteredData([]);

    setSearchText("");
  };

  useEffect(() => {
    const params = { search: searchText };


    dispatch(getUploadDataAction(params));

    // if (searchText !== "") {
    //   const params = { search: searchText };
  
    //   if (currentTab !== "all") {
    //     params.data_type = currentTab;
    //   }
  
    //   dispatch(getUploadDataAction(params));
    // }
  }, [searchText]);

  const showModal = (record) => {
    dispatch(getDocumentViewDetailsAction({ documentId: record.document_id, patient_id: record.patient_id }))
    // setModalData(record);
    setIsModalVisible(true);
    setIsConsentDisabled(true);
  };

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // setConsentChecked1(true);
    // setConsentChecked2(true);
  };

  const toggleConsent = () => {
    setIsConsentDisabled((prevState) => !prevState);
  };

  const handleCheckboxChange1 = (e) => {
    setConsentChecked1(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setConsentChecked2(e.target.checked);
  };

  const handleDeleteModalDelClick = () => {
    dispatch(deleteDocumentAction({ document_id: modalData?.document_id ,patient_id: modalData?.patient_id}))

    // toast.success(
    //   <>
    //     <span className="delete-toast-span">
    //       Patient data file deleted successfully.{" "}
    //       <Button
    //         className="delete-toast-span-details"
    //         onClick={handleDetailsModalClick}
    //         classNames="view-details-modal-btn"
    //       >
    //         View Details
    //       </Button>
    //     </span>
    //   </>,
    //   { className: "delete-toast" }
    // );
    setIsDeleteModalOpen(false);
  };

  const handleDetailsModalClick = () => {
    setIsDetailsModalOpen(true);
  };

  const handleDetailsModalOk = () => {
    setIsDetailsModalOpen(false);
  };
  const handleDetailsModalCancel = () => {
    setIsDetailsModalOpen(false);
  };

  const handleUpdateConsent = () => {
    const consentData = [
      {consent_type:"document_share_consent1",status: consentChecked1},
      {consent_type:"document_share_consent2",status: consentChecked2},
    ]
    dispatch(updateConsentDataAction({consents:consentData,document_id: modalData?.document_id}))
  };
  
  
  const downloadFile = async (fileId, fileName) => {
    try {
      const response = await axios.post(
        `${process.env.apiUrl}healthdata/downloadFile?documentId=${modalData?.document_id}`,
        {}, // Empty object for the request body if no additional data is needed
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${getCookie("accessToken")}`, // Correctly place headers outside the payload
          },
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            // Optionally, update a state to show the progress to the user
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          downloadSuccessDocumentSuccessAction({
            response: response,
          })
        );



        const file_path = `${process.env.apiUrl}${response?.data?.data?.file_path}`;

        const link = document.createElement("a");
        link.href = file_path; // Use the file path from the response
        link.target = "_blank"; // Open in a new tab
        link.setAttribute("download", response?.data?.data?.file_name || "downloaded-file"); // Optional: Set the downloaded file's name
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to open the file in a new tab
        link.remove(); // Remove the link from the DOM after opening
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", fileName); // Set the downloaded file's name
        // document.body.appendChild(link);
        // link.click();
        // link.remove();


        // Optionally, navigate to a success page
        navigate("/success-page?download=true");
      }
    } catch (error) {
      toast.error(
        <span className="delete-toast-span">
          Consent to download document not available.

        </span>,
        { className: "delete-toast" }

      
      );

      console.error("Download failed:", error);
      // Optionally, handle errors
    }
  };
  const summaryIcons =["home-icon","mixturemed-icon","cardiology-icon"]

  const rowClassName = (record) => {
    return record.file_storage_status === "deleted" ? `${getCookie("organization")} disabled-row` : "";
  };

  return (
    <div className="home-page">
      {/* Summary Section */}
      <div className="summary-section">
        {summaryData?.data?.map((item, index) => (
          <div className="summary-card" key={index}>
            <div className="summary-card-mask">
              <div className="title">{item?.type}</div>
              <div className="value">
                {formatDataSize(item.totalSize)}
                {/* <span className="format">{item.totalSize < 1048576 ? "MB" : "GB"}</span> */}
              </div>
              <SvgLoader
                width={50}
                height={50}
                svgName={summaryIcons[index]}
                className="svg-icons"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Metadata Section */}
      <div className="metadata-section">
        <div className="metadata-section-header">
          <div className="metadata-section-header-title">
            Document upload history
          </div>
          <Select
            showSearch={false}
            placeholder={
              <span>
                <UploadOutlined style={{ marginRight: 8 }} /> Upload Data
              </span>
            }
            onChange={(value) => navigate("/upload?uploadType=" + value)}
            options={[
              {
                value: "1",
                label: (
                  <div className="upload-data-btn-options">
                    <SvgLoader
                      width={24}
                      height={24}
                      svgName={"single-upload"}
                      className="single-upload"
                    />
                    Individual Patient
                  </div>
                ),
              },
              {
                value: "2",
                label: (
                  <div className="upload-data-btn-options">
                    <SvgLoader
                      width={24}
                      height={24}
                      svgName={"multiple-upload"}
                      className="single-upload"
                    />
                    Multiple Patients
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="tables-container">
          <Input
            placeholder="Search by file name"
            onChange={handleSearch}
            value={searchText}
            prefix={<SearchOutlined />}
            className="search-input"
          />
          <Tabs defaultActiveKey="all" onChange={handleTabChange}>
            <TabPane tab="All Data" key="all">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName}/>
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Clinic Data" key="clinic">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName}/>
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Trial Data" key="trial">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName}/>
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
            <TabPane tab="Health Data" key="health">
              {filteredData && filteredData.length > 0 ? (
                <CustomTable tableData={filteredData} columns={columns} rowClassName={rowClassName}/>
              ) : (
                <p className="no-data">No data available</p>
              )}
            </TabPane>
          </Tabs>

        </div>
      </div>

      {/* Modal */}
      <Modal
        centered
        title={
          <>
            {/* <SvgLoader
              width={22}
              height={17}
              svgName="ant-modal-upload"
              className=""
            /> */}
            <div className="modal-title-custom-left">
              <img
                src="/images/ant-modal-upload.png"
                alt="file upload"
                className="upload-icon-modal"
              />
              <span>Patient Data</span>
            </div>
          </>
        }
        // open
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={
          isConsentDisabled ? (
            <div className="modal-title-custom-right">
              <Button
                className="modal-action-btn modal-action-btn-delete"
                icon={
                  <SvgLoader
                    width={14}
                    height={16}
                    svgName="delete-icon-modal-red"
                    className="delete-button-modal"
                  />
                }
                onClick={showDeleteModal}
              >
                Delete
              </Button>
              <Button
                className="modal-action-btn modal-action-btn-reupload"
                icon={
                  <SvgLoader
                    width={14}
                    height={16}
                    svgName="reupload-icon-modal"
                    className="delete-button-modal"
                  />
                }
                onClick={() => navigate(`/upload?reupload=true&&uploadType=1&&patientId=${modalData?.patient_id}&&document_id=${modalData?.document_id}&&consent1=${modalData?.consents[1]?.status}&&consent2=${modalData?.consents[2]?.status}&&data_type=${modalData?.data_type}`)}
              >
                Reupload
              </Button>
              <Button
                className="modal-action-btn modal-action-btn-download"
                icon={
                  <SvgLoader
                    width={20}
                    height={20}
                    svgName="download-icon-modal-white"
                  />
                }
                onClick={() => downloadFile(modalData?.document_id, modalData?.file_name)}
              >
                Download Document
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className="modal-action-btn modal-action-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="modal-action-btn modal-action-btn-save"
                onClick={()=>handleUpdateConsent()}
              >
                Save Changes
              </Button>
            </div>
          )
        }
        className="preview-modal patient-data-modal"
      >
        <hr />
        {modalData && (
          <div className="points">
            <p>
              <span>File Name</span> {modalData?.file_name}
            </p>
            <p>
              <span>Patient ID</span> {modalData?.patient_id || " - "}
            </p>
            <p className="data-type">
              <span>Data Type</span> {modalData?.data_type}
            </p>
            <p className="data-type">
              <span>Document Id</span><div className="txn-id">{modalData?.document_id}</div> 
            </p>
            {!modalData?.previous_document_transaction_id?.blockchain_txn_id ?  <p>
              <span >Data Transaction Id</span> <div className="txn-id">{modalData?.upload_document_transaction_id?.blockchain_txn_id}</div> 

              {/* <span >Data Hash</span>   <a href={`${process.env.explorerUrl}:/?tab=transactions&&transId=${modalData?.upload_document_transaction_id?.blockchain_txn_id}`} className="txn-id" target="__blank"> {modalData?.upload_document_transaction_id?.blockchain_txn_id}</a> */}
            </p> :
              <p className="hash-section">
                <span>Data Transaction Id:</span>{" "}
                <div className="hash-section-hashes">
                  <div className="transactions">
                    <div className="upload-hash">1. Upload hash</div>{" "}
                    <div className="txn-id"> {modalData?.upload_document_transaction_id?.blockchain_txn_id}    </div>             </div>
                  <div className="line"></div>
                  <div className="transactions">
                    <div className="delete-hash">2. Previous hash</div>{" "}
                    <div className="txn-id"> {modalData?.previous_document_transaction_id?.blockchain_txn_id}      </div>          </div>
                </div>
              </p>}
            <p>
              <span>Upload Date</span> {formatCustomDate(modalData?.uploaded_on)}
            </p>
            <p>
              <span>Data Size</span> <div className="data-size">{formatDataSize(modalData?.data_size_bytes)}</div>
            </p>
            <div className="consent-section">
              <Checkbox
                className="custom-checkbox"
                checked={true}
                disabled={true}
                onChange={handleCheckboxChange1}
              >
                I agree to all the <a href="#">Terms of Services</a> and{" "}
                <a href="#">Privacy Policy</a>.
              </Checkbox>
              <Checkbox
                defaultChecked={getDocumentViewDetails?.data?.consents?.[0]?.status}
                checked={consentChecked1}
                disabled={isConsentDisabled}
                onChange={handleCheckboxChange1}
              >
  Consent 1
              </Checkbox>
              <Checkbox
                defaultChecked={getDocumentViewDetails?.data?.consents?.[1]?.status}
                checked={consentChecked2}
                disabled={isConsentDisabled}
                onChange={handleCheckboxChange2}
              >
  Consent 2
              </Checkbox>
            </div>
            {isConsentDisabled && (
              <Button
                type="link"
                className="consent-btn"
                onClick={toggleConsent}
              >
                Do you want to change the consent?
              </Button>
            )}
            {/* <Button
              className="custom-btn-solid"
              onClick={handleCancel}
              disabled={isConsentDisabled}
            >
              Save Changes
            </Button> */}
          </div>
        )}
      </Modal>

      {/* Delete Modal */}
      <Modal
        centered
        open={isDeleteModalOpen}
        onOk={handleOk}
        onCancel={handleDeleteModalCancel}
        className="delete-modal"
        footer={[
          <Button
            key="submit"
            className="delete-modal-action-btn delete-modal-action-btn-delete"
            onClick={handleDeleteModalDelClick}
          >
            Delete
          </Button>,
          <Button
            key="cancel-button"
            onClick={handleDeleteModalCancel}
            className="delete-modal-action-btn delete-modal-action-btn-cancel"
          >
            Cancel
          </Button>,
        ]}
      >
        <SvgLoader
          width={241}
          height={200}
          svgName="delete-file-icon"
          className="svg-icons"
        />
        <h1 className="sure-text">
          Are you sure you want to delete <br />
          “Patient Data” file?
        </h1>
      </Modal>

      {/* View Details Modal */}
      <Modal
        centered
        title={
          <>
            {/* <SvgLoader
              width={22}
              height={17}
              svgName="ant-modal-upload"
              className=""
            /> */}
            <div className="modal-title-custom-left">
              <img
                src="/images/ant-modal-upload.png"
                alt="file upload"
                className="upload-icon-modal"
              />
              <span>Patient Data</span>
            </div>
          </>
        }
        open={isDetailsModalOpen}
        onOk={handleDetailsModalOk}
        onCancel={handleDetailsModalCancel}
        footer={
          <div className="details-modal-footer">
            <Button
              className="delete-modal-action-btn"
              onClick={handleDetailsModalCancel}
            >
              Cancel
            </Button>
            <Button
              className="details-modal-upload-btn"
              onClick={() => navigate(`/upload?reupload=true&&uploadType=1&&patientId=${modalData?.patient_id}&&document_id=${modalData?.document_id}&&consent1=${modalData?.consents[1]?.status}&&consent2=${modalData?.consents[2]?.status}&&data_type=${modalData?.data_type}`)}
            >
              Reupload
            </Button>
          </div>
        }
        className="details-modal"
      >
        <hr />
        {modalData && (
          <div className="points">
            <p>
              <span>File Name:</span> {modalData.file_name}
            </p>
            <p className="data-type">
              <span>Data Type:</span> {modalData.data_type}
            </p>
            <p className="data-type">
              <span>Data Id:</span> <div className="txn-id">{modalData?.document_id}</div> 
            </p>

            <p className="hash-section">
              <span>Data Transaction Id:</span>{" "}
              <div className="hash-section-hashes">
                <div className="transactions">
                  <div className="upload-hash">1. Upload hash</div>{" "}
                  <div className="txn-id"> {modalData?.upload_document_transaction_id?.blockchain_txn_id}</div>
                </div>
                <div className="line"></div>
                <div className="transactions">
                  <div className="delete-hash">2. Delete hash</div>{" "}
                  <div className="txn-id"> {modalData?.delete_document_transaction_id?.blockchain_txn_id}</div>
                </div>
              </div>
            </p>
            <p>
              <span>Upload Date:</span> {formatCustomDate(modalData.uploaded_on)}
            </p>
            <p>
              <span>Data Size:</span> {modalData.data_size_bytes}
            </p>
          </div>
        )}
        <hr />
      </Modal>

     
    </div>
  );
};

export default ClinicDashboard;
