import { genericActionCreator } from "../../app/epics";
// import {  handleLogout } from "../../common/utils";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const GET_UPLOAD_DATA = "GET_UPLOAD_DATA";
const GET_UPLOAD_DATA_SUCCESS = "GET_UPLOAD_DATA_SUCCESS";
const GET_UPLOAD_DATA_FAILURE = "GET_UPLOAD_DATA_FAILURE";
const GET_UPLOAD_DATA_RESET = "GET_UPLOAD_DATA_RESET";







export const getUploadDataAction = (payload) =>
  genericActionCreator(
    GET_UPLOAD_DATA,
    payload,
    {
      url: `${process.env.apiUrl}healthdata/uploadData`,
      method: "GET",
      auth: true,
      //   headers: {
      //     Authorization: getCookie("accessToken"),
      //     // "Content-Type": "ArrayBuffer",
      //     // ContentType: "ArrayBuffer",
      //   }
        
              
      params: {
        data_type: payload?.data_type,
        search: payload?.search,
      },
    }
  );
export const getUploadDataResetAction = () => ({
  type: GET_UPLOAD_DATA_RESET,
});
  
export function getUploadDataReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case GET_UPLOAD_DATA_RESET: {
    return INITIAL_STATE;
  }
  case GET_UPLOAD_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case GET_UPLOAD_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case GET_UPLOAD_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}