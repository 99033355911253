import React from "react";
import LoginForm from "../../components/LoginForm/index.js";


const LoginPage =()=>{ 
 
  return (
    <div className="loginPg-container">
      <div className="loginPg-container-wrapper">
        <div className="login-section1">
          <img src="/images/login-page.png" alt="Data depection" />
          <div className="login-section1-title">
            <h3>
              Securely access patient and clinical trial data on our
              blockchain-based, AI-powered hospital dashboard. Designed for
              healthcare professionals, this platform ensures data privacy,
              seamless insights, and advanced analytics to support better
              patient care.
            </h3>
          </div>
        </div>
        <div className="login-section2">
          <div className="login-section2-form-heading">
            <img src="/images/svg/mediledger-logo.svg" alt="MediLedger" width={301} height={50} />
          </div>
          <div className="login-section2-form">
            <h1>Login</h1>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
