/* eslint-disable no-unused-vars */

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};
  
const UPLOAD_SUCCESS_DATA = "UPLOAD_SUCCESS_DATA";
const UPLOAD_SUCCESS_DATA_SUCCESS = "UPLOAD_SUCCESS_DATA_SUCCESS";
const UPLOAD_SUCCESS_DATA_FAILURE = "UPLOAD_SUCCESS_DATA_FAILURE";
const UPLOAD_SUCCESS_DATA_RESET = "UPLOAD_SUCCESS_DATA_RESET";


const DOWNLOAD_SUCCESS_DATA = "DOWNLOAD_SUCCESS_DATA";
const DOWNLOAD_SUCCESS_DATA_SUCCESS = "DOWNLOAD_SUCCESS_DATA_SUCCESS";
const DOWNLOAD_SUCCESS_DATA_FAILURE = "DOWNLOAD_SUCCESS_DATA_FAILURE";
const DOWNLOAD_SUCCESS_DATA_RESET = "DOWNLOAD_SUCCESS_DATA_RESET";




    
export const uploadSuccessDocumentResetAction = () => ({
  type: UPLOAD_SUCCESS_DATA_RESET,
});
  
export function uploadSuccessDocumentReducer(state, action = null) {
  if (typeof state === "undefined") {
    return INITIAL_STATE;
  }
  switch (action.type) {
  case UPLOAD_SUCCESS_DATA_RESET: {
    return INITIAL_STATE;
  }
  case UPLOAD_SUCCESS_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case UPLOAD_SUCCESS_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case UPLOAD_SUCCESS_DATA_FAILURE: {
    if (action?.payload?.status === 401) {
      // toast.error("Session Expired. Please login again");
      // handleLogout();
    }
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}




    
export const downloadSuccessDocumentAction = () => ({
  type: DOWNLOAD_SUCCESS_DATA,
});

export const downloadSuccessDocumentSuccessAction = (response) => ({
  type: DOWNLOAD_SUCCESS_DATA_SUCCESS,
  payload: { response },
});

export const downloadSuccessDocumentFailureAction = (error) => ({
  type: DOWNLOAD_SUCCESS_DATA_FAILURE,
  payload: { error },
});
  
export function downloadSuccessDocumentReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
  case DOWNLOAD_SUCCESS_DATA: {
    return {
      ...state,
      data: null,
      loading: true,
      error: false,
      flag: false,
    };
  }
  case DOWNLOAD_SUCCESS_DATA_SUCCESS: {
    return {
      ...state,
      data: action.payload.response,
      loading: false,
      error: false,
      flag: true,
    };
  }
  case DOWNLOAD_SUCCESS_DATA_FAILURE: {
    return {
      ...state,
      data: null,
      loading: false,
      error: true,
      flag: false,
    };
  }
  default:
    return state;
  }
}
